import { Row, Col } from '@everlywell/leaves';
import React from 'react';
import { IGatsbyImage } from 'utils/types';

import * as S from './styles';

export interface PressSectionProps {
  title: string;
  desktopImages: IGatsbyImage[];
}

export const PressSection: React.FC<PressSectionProps> = ({
  title,
  desktopImages,
}) => (
  <S.Container>
    <Row center="xs">
      <Col xs={12} lg={10}>
        <S.SectionTitle text={title} />
      </Col>
    </Row>

    <S.StyledRow amountOfImages={desktopImages.length}>
      <S.LogoContainer xs={12} lg={10}>
        {desktopImages.map((item, index) => (
          <S.ImageWrapper key={index}>
            <S.GatsbyImg
              image={item.gatsbyImageData}
              imgStyle={{ objectFit: 'contain', width: '100%' }}
              alt={item.description || ''}
            />
          </S.ImageWrapper>
        ))}
      </S.LogoContainer>
    </S.StyledRow>
  </S.Container>
);

export default PressSection;
