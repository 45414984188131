import React from 'react';
import { IGatsbyImage } from 'utils/types';
import PressSection from '../../../components/Homepage/PressSection';

export interface PressContainerProps {
  content: {
    title: string;
    desktopImages: IGatsbyImage[];
  };
}

export const PressContainer: React.FC<PressContainerProps> = ({ content }) => {
  if (!content) {
    return null;
  }
  const { title, desktopImages: rawDesktopImages } = content;

  return <PressSection title={title} desktopImages={rawDesktopImages} />;
};

export default PressContainer;
