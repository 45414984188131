import {
  Container as RawContainer,
  size,
  Col,
  mediaQueries,
} from '@everlywell/leaves';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import { SectionTitle as RawSectionTitle } from '../../Enterprise/SectionTitle';

const MAX_NUM_BEFORE_ROW = 3;

const Container = styled(RawContainer)`
  padding-top: ${size.xl4}px;
  padding-bottom: ${size.xl4}px;
`;

const SectionTitle = styled(RawSectionTitle)`
  margin-bottom: ${size.xl1}px;
  text-align: center;
`;

const ImageWrapper = styled.div`
  width: 50%;
  margin: ${size.md}px 0;
  padding: 0 ${size.md}px;
`;

const LogoContainer = styled(Col)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -${size.md}px;

  ${mediaQueries.forTabletVerticalUp} {
    margin: 0 -${size.lg}px;

    ${ImageWrapper} {
      width: 25%;
      padding: 0 ${size.md}px;
    }
  }

  ${mediaQueries.forDesktopUp} {
    margin: 0 -${size.xl1}px;

    ${ImageWrapper} {
      width: 20%;
      padding: 0 ${size.lg}px;
    }
  }
`;

const GatsbyImg = styled(GatsbyImage)`
  width: 100%;
  height: 52px;
  ${mediaQueries.forDesktopUp} {
    height: ${size.xl4}px;
  }
`;

interface StyledRowProps {
  amountOfImages: number;
}
const StyledRow = styled.div<StyledRowProps>`
  box-sizing: border-box;
  display: flex;
  flex-direction: ${(props) =>
    props.amountOfImages > MAX_NUM_BEFORE_ROW ? 'row' : 'column'};
  flex-wrap: wrap;
  margin: 0
    ${(props) =>
      props.amountOfImages > MAX_NUM_BEFORE_ROW ? '-8px' : `${size.lg}px`};
  flex: 0 1 auto;
  justify-content: space-around;

  ${mediaQueries.forDesktopUp} {
    flex-direction: row;
  }
`;

export {
  Container,
  SectionTitle,
  ImageWrapper,
  LogoContainer,
  GatsbyImg,
  StyledRow,
};
